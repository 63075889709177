<template>
  <!--
    The view for the Rollout Calendar
  -->
  <Portlet
    :title="$t('rolloutCalendar')"
    icon="scroll"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['mr-3 alt-pointer color-primary', { 'fa-spin' : loading}]"
        icon="sync-alt"
      />
    </template>
    <div class="row">
      <div
        v-for="(rType, index) in rolloutTypes"
        :key="`li-item${ index }`"
        class="rollout-legend ml-3 row"
        @click="setRolloutType(rType)"
      >
        <div
          :style="'background-color:' + rType.color + ';'"
          class="mt-1"
        />
        <p :class="['ml-2 mr-4', {'selected-type' : excludedTypes.includes(rType)}]">
          {{ rType.name }}
        </p>
      </div>
    </div>
    <RolloutCalendar
      :excluded-types="excludedTypes"
      @setLoading="setLoading"
    />
  </Portlet>
</template>

<script>
export default {
  name: "RolloutCalendarView",
  components: {
    RolloutCalendar: () => import('@/components/OnCallDuty/RolloutCalendar.vue'),
  },
  data () {
    return {
      excludedTypes: [],
      rolloutTypes: [
        {name: 'Go-Live', color: 'red'},
        {name: 'Start Date / Due Date', color: '#ff8000'}
      ],
      loading: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('rolloutCalendar')
    }
  },
  methods: {
    setLoading (val) {
      this.loading = val;
    },
    setRolloutType (val) {
      if(this.excludedTypes.includes(val)) {
        this.excludedTypes = this.excludedTypes.filter(item => item !== val)
      }
      else {
        this.excludedTypes.push(val);
      }
    },
  }
}
</script>

<style scoped>
.rollout-legend p{
  font-size: 15px
}

.rollout-legend {
  cursor: pointer;
}

.rollout-legend p.selected-type {
  text-decoration: line-through;
  font-weight: bold;
}

.rollout-legend div {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
</style>